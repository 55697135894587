
























































































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import {
  CycleDonateConfigDto,
  DataDictionaryDtoPagedResultDto,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";
import DonationMonthlyStatusTag from "@/views/fund/fundDonationMonthly/components/DonationMonthlyStatusTag.vue";
import EnumTypeTag from "@/components/enumTypeTagFormatter/EnumTypeTag.vue";

@Component({
  name: "FundDonationMonthlyList",
  components: {
    EnumTypeTag,
    DonationMonthlyStatusTag,
    PagedTableView,
    AbSelect,
    ExportButton,
  },
})
export default class FundDonationMonthlyList extends Vue {
  donorType: any = [];
  donationType: any = [];

  queryForm = {};
  detailId = 0;

  created() {
    this.fetchEnumType();
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "DonorType",
      })
      .then((res) => {
        this.donorType = res;
      });
    await api.enumService
      .getValues({
        typeName: "DonationType",
      })
      .then((res) => {
        this.donationType = res;
      });
  }

  // 获取表数据
  fetchData(params: any) {
    return api.cycleDonateConfig.getAll(params);
  }

  // 查看详情
  jumpDetail(index: number, row: CycleDonateConfigDto) {
    this.detailId = row.id!;
    this.$router.push({
      name: "fundDonationMonthlyDetail",
      params: { id: row.id!.toString() },
    });
  }

  // 停止月捐
  stop(id: number) {
    this.$confirm("确定要停止月捐吗?", "提示").then(async () => {
      await api.fundDonationMonthly
        .stop({
          body: { id: id },
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
    });
  }
}
